.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* .mailchimp button{
  color: #fff;
  background-color: #dc3e2f;
  font-size: 16px;
  padding: 10px;
  border-radius: 8px;
  border:none;
  cursor: pointer;
  width:190px;
  height:50px;
  margin-left:13%;
  opacity: 0.9;
} */


.mailchimp button {
  clear: left;
  color: black;
  background-color: #d5f9a1;
  height:25px;
  border-radius: 8px;
  padding: 5px;
  font-size: 8px;
  align-items: center;
  justify-content: center;
}

.mailchimp input{
  padding: 5px;
  background-color: #fff;
  border: none;
  font-size: 10px;
  margin-left:20px;
  border-radius: 8px;
} 

.mailchimp body{
  font:14px 'Helvetica', Arial, sans-serif;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.mailchimp input{
  padding: 5px;
  background-color: #fff;
  border: none;
  font-size: 10px;
  margin-left:20px;
}

/* .mailchimp input:focus, button:focus{
  box-shadow: none;
  border:none;
  outline-width:none;
}
.mailchimp input:focus,
.mailchimp select:focus,
.mailchimp textarea:focus,
.mailchimp button:focus {
    outline: none;
}
*/

.spacingComponent {
    margin-top: 150px;
}

.spacingMedium{
    margin-top: 10px;
}

